import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import { FaEnvelope } from 'react-icons/fa'
import { FaPhoneAlt } from 'react-icons/fa'
import { FaMapMarkerAlt } from 'react-icons/fa'

const NewsletterPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Newsletter Archive</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>2025</h2>
                    <p className="d-flex">
                        <a href="/newsletters/2025 Q1.pdf" target="_blank" class="button">2025 Q1</a>
                        <a href="/newsletters/2025 Q2.pdf" target="_blank" class="button">2025 Q2</a>
                    </p>
                    <div className="spacer"></div>
                    <h2>2024</h2>
                    <p className="d-flex">
                        <a href="/newsletters/2024 Q1.pdf" target="_blank" class="button">2024 Q1</a>
                        <a href="/newsletters/2024 Q2.pdf" target="_blank" class="button">2024 Q2</a>
                        <a href="/newsletters/2024 Q3.pdf" target="_blank" class="button">2024 Q3</a>
                        <a href="/newsletters/2024 Q4.pdf" target="_blank" class="button">2024 Q4</a>
                    </p>
                    <div className="spacer"></div>
                    <h5>Signup for our newsletter</h5>
                    <p>If you would like to enjoy our newsletter to find out about Habitat Northwoods upcoming events, email <a href="mailto:mailinglist@habitatnorthwoods.org">mailinglist@habitatnorthwoods.org</a></p>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default NewsletterPage

export const Head = () => (
	<Seo
        title="Newsletter Archive | Habitat For Humanity Northwoods Wisconsin"
    />
)
